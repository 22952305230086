const getUser = async ( props ) => {
	let user;

	await fetch( '/endpoints/react/user-handler.php?retrieve', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Cache-Control': 'no-cache'
		},
	})
	.then( res => res.json() )
	.then(
		( result ) => {
			if ( result.response == 'success' && result.data ) {
				user = result.data;
			} else {
				props.history.push( '/sign-in' );
			}
		},
		( error ) => {

		}
	)

	return user;
}

export default getUser;
