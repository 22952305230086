const getFolder = async ( id ) => {
	let folder;

	const data = {
		location:id
	}

	await fetch( '/endpoints/react/file-handler.php?getFolder', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Cache-Control': 'no-cache'
		},
		body: JSON.stringify( data ),
	})
	.then( res => res.json() )
	.then(
		( result ) => {
			if ( result.response == 'success' && result.data ) {
				folder = result.data;
			}
		},
		( error ) => {

		}
	)

	return folder;
}

export default getFolder;
