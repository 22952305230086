import React, { Component } from "react";
import LeftPanel from "../Navigation/LeftPanel";
import SignInUser from "./Partials/SignInUser";
import Theme from "../../Theme/Variables";
import { ThemeProvider, StylesProvider, Container, Button, Typography } from '@material-ui/core';

export default class SignIn extends Component {
	constructor( props ) {
		super( props );
	}

	render() {
		return (
			<div className='page-signin page'>
				<Container component="main">
					<ThemeProvider theme={ Theme }>
						<StylesProvider injectFirst>
							<section className="sign-panel">
								<div className="logo-wrapper">
									<img src="/assets/src/img/straact-bird-blue-logo.png" alt="Straact logo" />
								</div>
								<h1>Sign in</h1>
								<SignInUser />
							</section>
							<section className="explore-panel">
								<h4>Trusted by 100.000 students</h4>
								<Button variant="contained" color="primary">Hello World</Button>
							</section>
						</StylesProvider>
					</ThemeProvider>
				</Container>
			</div>
		)
	}
}
