import React, { Component } from "react";

export default class PreloadFiles extends Component {
	render() {
		return (
			<section className="preload-files">
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
				<div className="preload-block"><div className="tiny"></div><div className="wide"></div></div>
			</section>
		)
	}
}
