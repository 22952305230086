import React, { Component } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./Routes/Switch";

export default class App extends Component {
	render() {
		return (
			<div className='app'>
				<Router>
					<Routes />
				</Router>
			</div>
		)
	}
}
