import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
	typography: {
		h1: {
			fontSize: '3rem',
			fontWeight: '700'
		},
		button: {
			fontSize: '1rem',
		},
	},
});

export default Theme;
