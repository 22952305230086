import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Start from '../Components/Start/Start';
import SignIn from '../Components/Start/SignIn';
import Create from '../Components/Create/Create';
import Notes from '../Components/Notes/Notes';
import Settings from '../Components/Settings/Settings';
import Folder from '../Components/Folders/Folder';

const Routes = () => (
	<Switch>
		<Route exact path='/start'>
			<Start />
		</Route>
		<Route exact path='/sign-in'>
			<SignIn />
		</Route>
		<Route exact path='/create'>
			<Create />
		</Route>
		<Route exact path='/notes'>
			<Notes />
		</Route>
		<Route exact path='/settings'>
			<Settings />
		</Route>
		<Route path='/folders/:id'>
			<Folder />
		</Route>
	</Switch>
);

export default Routes;
