const getFilesView = async ( props, location ) => {
	let view;

	const data = {
		location:( props.match.params.id ? props.match.params.id : location )
	}

	await fetch( '/endpoints/react/file-handler.php?view', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Cache-Control': 'no-cache'
		},
		body: JSON.stringify( data ),
	})
	.then( res => res.json() )
	.then(
		( result ) => {
			if ( result.response == 'success' && result.data ) {
				view = result.data;
			}
		},
		( error ) => {

		}
	)

	return view;
}

export default getFilesView;
