import React, { useState, setState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

function CreateUser() {
	let history = useHistory();

	// Initialize states for later use
	const [email, setEmail] = useState( '' );
	const [pass, setPass] = useState( '' );
	const [strength, setStrength] = useState( '' );
	const [errorMessage, setErrorMessage] = useState( '' );

	/**
	* Error handler
	*
	*
	* @return string
	*/
	const errorHandler = ( type, reason ) => {

		return <div className="error-message">{ reason }</div>
	}

	/**
	* Form submit handler
	*
	*
	* @return boolean
	*/
	const createUser = ( e ) => {
		e.preventDefault();

		if ( strength == 'Strong' && email != '' ) {
			const data = {
				email:email,
				pass:pass
			}

			fetch( '/endpoints/react/user-handler.php?create', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Cache-Control': 'no-cache'
				},
				body: JSON.stringify( data ),
			})
			.then( res => res.json() )
			.then(
				( result ) => {
					if ( result.response == 'success' ) {
						history.push( result.url );
					} else {
						setErrorMessage( errorHandler( result.type, result.reason ) );
					}
				},
				( error ) => {
					console.log( error );
					history.push( '/error' );
				}
			)
		} else {
			return false;
		}
	}

	/**
	* Validate the typed password and see if it complies with our terms
	*
	*
	* @return setStrength | setPass
	*/
	const passwordController = ( value ) => {

		let capital = false;
		let number = false;
		let len = false;

		if ( /[A-Z]/.test( value ) ) {
			capital = true;
		}

		if ( /[0-9]/.test( value ) ) {
			number = true;
		}

		if ( value.length > 7 ) {
			len = true;
		}

		if ( value.length == 0 ) {
			setStrength( '' );
		} else {
			setStrength( 'Very weak' );
		}

		if ( ( capital || number || len ) == true ) {
			setStrength( 'Weak' );
		}

		if ( ( ( capital && number ) == true || ( capital && len ) == true ) || ( len && number ) == true ) {
			setStrength( 'Medium' );
		}

		if ( ( capital && number && len ) == true ) {
			setStrength( 'Strong' );
			setPass( value );
		}
	}


	let strengthIndicator = '';
	let button = '';

	if ( strength != '' ) {
		strengthIndicator = <div className={'password-strength-indicator ' + strength.replace( ' ', '-' ).toLowerCase() }><div className="indi"><b>Strength:</b> <span className="strength-type">{ strength }</span></div><div className="bar"><span></span></div></div>;
	}

	if ( strength == 'Strong' && email.includes( '@' ) ) {
		button = <Button type="submit" fullWidth variant="contained" color="primary">Create my account</Button>;
	} else {
		button = <Button type="submit" fullWidth variant="contained" className="disabled" color="primary">Create my account</Button>;
	}

	return (
		<div className="form-wrapper field-wrapper">
			<form onSubmit={ createUser } noValidate>
				<TextField
					type="email"
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email-field"
					label="E-mail"
					name="email"
					autoComplete="new-email"
					onChange={ event => setEmail( event.target.value ) }
				/>
				<TextField
					type="password"
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="password-field"
					label="Password"
					name="password"
					autoComplete="new-password"
					onChange={ event => passwordController( event.target.value ) }
				/>
				{ strengthIndicator }
				<FormControlLabel
					control={<Checkbox name="offers" />}
					label="I allow Straact to send me account related post"
				/>
				{ errorMessage }
				{ button }
				<div className="seperator"><span>or</span></div>
			</form>
		</div>
	);
}

export default CreateUser;
