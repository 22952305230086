import React, { useState, setState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

function SignInUser() {
	let history = useHistory();

	// Initialize states for later use
	const [email, setEmail] = useState( '' );
	const [pass, setPass] = useState( '' );
	const [errorMessage, setErrorMessage] = useState( '' );

	/**
	* Error handler
	*
	*
	* @return string
	*/
	const errorHandler = ( type, reason ) => {

		return <div className="error-message">{ reason }</div>
	}

	/**
	* Form submit handler
	*
	*
	* @return boolean
	*/
	const signIn = ( e ) => {
		e.preventDefault();

		if ( pass != '' && email != '' ) {
			const data = {
				email:email,
				pass:pass
			}

			fetch( '/endpoints/react/user-handler.php?signIn', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Cache-Control': 'no-cache'
				},
				body: JSON.stringify( data ),
			})
			.then( res => res.json() )
			.then(
				( result ) => {
					if ( result.response == 'success' ) {
						history.push( result.url );
					} else {
						setErrorMessage( errorHandler( result.type, result.reason ) );
					}
				},
				( error ) => {
					console.log( error );
					history.push( '/error' );
				}
			)
		} else {
			return false;
		}
	}

	let button = '';

	if ( pass == '' || ! email.includes( '@' ) ) {
		button = <Button type="submit" fullWidth variant="contained" className="disabled" color="primary">Sign me in</Button>
	} else {
		button = <Button type="submit" fullWidth variant="contained" color="primary">Sign me in</Button>
	}

	return (
		<div className="form-wrapper field-wrapper">
			<form onSubmit={ signIn } noValidate>
				<TextField
					type="email"
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email-field"
					label="E-mail"
					name="email"
					autoComplete="new-email"
					onChange={ event => setEmail( event.target.value ) }
				/>
				<TextField
					type="password"
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="password-field"
					label="Password"
					name="password"
					autoComplete="new-password"
					onChange={ event => setPass( event.target.value ) }
				/>
				{ errorMessage }
				{ button }
				<div className="seperator"><span>or</span></div>
			</form>
		</div>
	);
}

export default SignInUser;
