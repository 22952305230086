import React, { Component } from "react";
import LeftPanel from "../Navigation/LeftPanel";

export default class Create extends Component {
	render() {
		return (
			<div className='create-page page'>
				<LeftPanel />

				<div className="center-panel">
					<h1>Create</h1>
				</div>
			</div>
		)
	}
}
