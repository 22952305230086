import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import getUser from '../../Utils/User/GetUser';
import getFilesView from '../../Utils/Files/GetView';
import getFolder from '../../Utils/Files/GetFolder';
import LeftPanel from "../Navigation/LeftPanel";
import TopBar from "../Notes/Partials/TopBar";
import FilesView from "../Notes/Partials/FilesView";
import Theme from "../../Theme/Variables";
import { ThemeProvider, StylesProvider, Container, Button, Typography } from '@material-ui/core';

class Folder extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			user: {},
			folder: {
				id: this.props.match.params.id
			},
			folders: [],
			preloadFiles: true,
		};
	}

	componentDidMount() {
		getUser( this.props ).then( data => {
			this.setState({
				user: {
					'user_e_id': data.user_e_id,
					'email': data.email,
					'first_name': data.first_name,
					'last_name': data.last_name,
					'type': data.account_type,
					'avatar': data.avatar
				}
			});
		});

		getFolder( this.props.match.params.id ).then( data => {
			this.setState({
				folder: {
					id: data.folder_id,
					name: data.name
				}
			});
		});

		getFilesView( this.props ).then( data => {
			this.setState({
				folders:data,
				preloadFiles:false
			});
		});
	};

	componentDidUpdate( prevProps, prevState ) {
		if ( prevProps.location.pathname !== this.props.location.pathname ) {
			getFolder( this.props.match.params.id ).then( data => {
				this.setState({
					folder: {
						id: data.folder_id,
						name: data.name
					}
				});
			});
			getFilesView( this.props ).then( data => {
				this.setState({
					folders:data,
					preloadFiles:false
				});
			});
		}
	}

	appendFolder = ( folder ) => {
		let folders = this.state.folders;
		folders.unshift({
			folder_id: folder.folder_id,
			name: folder.name,
			changed: ''
		})

		this.setState({
			folders:folders
		});
	}

	render() {
		return (
			<div className='page-folders page'>
				<Container component="main">
					<ThemeProvider theme={ Theme }>
						<StylesProvider injectFirst>
							<LeftPanel
								first_name={ this.state.user.first_name }
								last_name={ this.state.user.last_name }
								email={ this.state.user.email }
								type={ this.state.user.type }
							/>

							<div className="center-panel">
								<TopBar
									folder_id={ this.state.folder.id }
									headline={ this.state.folder.name }
									appendFolder={ this.appendFolder }
								/>

								<FilesView
									folders={ this.state.folders }
									preload={ this.state.preloadFiles }
								/>
							</div>
						</StylesProvider>
					</ThemeProvider>
				</Container>
			</div>
		)
	}
}

export default withRouter( Folder );
