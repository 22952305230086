import React, { useState, setState } from "react";
import { Link } from 'react-router-dom';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

function LeftPanel( props ) {

	// Initialize states for later use
	const [userMenu, setUserMenu] = useState( false );

	let userNavBlock =
		<div className="logged-user__nav">
			<div className="avatar">
				<img alt="Avatar" src={ props.avatar ? props.avatar : 'assets/src/img/avatar.svg' } />
			</div>
			<ul>
				<Link to='/settings'><li><span>Settings</span><SettingsRoundedIcon /></li></Link>
				<Link to='/settings'><li><span>Upgrade account</span><StarRoundedIcon /></li></Link>
				<Link to='/sign-out'><li><span>Sign out</span><ExitToAppRoundedIcon /></li></Link>
			</ul>
		</div>
	;

	return (
		<section className="left-panel">
			<div className="logo-wrapper">
				<img src="/assets/src/img/straact-bird-blue-logo.png" alt="Straact logo" />
			</div>
			<nav>
				<ul>
					<Link to='/start'><li>Start</li></Link>
					<Link to='/create'><li>Create</li></Link>
					<Link to='/notes'><li>Notes</li></Link>
					<Link to='/settings'><li>Settings</li></Link>
				</ul>
			</nav>
			<div
				className={ userMenu ? 'logged-user active' : 'logged-user' }
				onClick={ event => setUserMenu( ! userMenu ) }
			>
				{ userMenu ? userNavBlock : '' }
				<div className="meta">
					<div className={ props.first_name && props.last_name ? 'fullname' : 'email' }>
						{ props.first_name && props.last_name ? props.first_name + ' ' + props.last_name : props.email }
					</div>
					<div className="account-type">
						{ props.type }
					</div>
				</div>
				<div className="account-arrow">
					<KeyboardArrowDown />
				</div>
			</div>
		</section>
	)
}

export default LeftPanel;
