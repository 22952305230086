import React from 'react';
import ReactDOM from 'react-dom';
import App from './js/App';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById( 'react-load' )
);
