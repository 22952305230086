import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LeftPanel from "../Navigation/LeftPanel";
import Theme from "../../Theme/Variables";
import { ThemeProvider, StylesProvider, Container, Button, Typography } from '@material-ui/core';

class Settings extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			user: {
				'user_e_id': '',
				'email': '',
				'first_name': '',
				'last_name': '',
				'type': '',
				'avatar': ''
			}
		};
	}

	componentDidMount() {
		fetch( '/endpoints/react/user-handler.php?retrieve', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Cache-Control': 'no-cache'
			},
		})
		.then( res => res.json() )
		.then(
			( result ) => {
				if ( result.response == 'success' && result.user ) {
					this.setState({
						user: {
							'user_e_id': result.user.user_e_id,
							'email': result.user.email,
							'first_name': result.user.first_name,
							'last_name': result.user.last_name,
							'type': result.user.account_type,
							'avatar': result.user.avatar
						}
					});
				} else {
					this.props.history.push( '/sign-in' );
				}
			},
			( error ) => {

			}
		)
	}

	render() {
		return (
			<div className='page-settings page'>
				<Container component="main">
					<ThemeProvider theme={ Theme }>
						<StylesProvider injectFirst>
							<LeftPanel
								first_name={ this.state.user.first_name }
								last_name={ this.state.user.last_name }
								email={ this.state.user.email }
								type={ this.state.user.type }
							/>
							<h1>Mit user_e_id: { this.state.user.user_e_id }</h1>
						</StylesProvider>
					</ThemeProvider>
				</Container>
			</div>
		)
	}
}

export default withRouter( Settings );
