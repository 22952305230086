import React, { Component } from "react";
import LeftPanel from "../Navigation/LeftPanel";
import CreateUser from "./Partials/CreateUser";
import Theme from "../../Theme/Variables";
import { ThemeProvider, StylesProvider, Container, Button, Typography } from '@material-ui/core';

export default class Start extends Component {
	constructor( props ) {
		super( props );
	}

	// https://maze.design/signup

	render() {
		return (
			<div className='page-start page'>
				<Container component="main">
					<ThemeProvider theme={ Theme }>
						<StylesProvider injectFirst>
							<section className="sign-panel">
								<div className="logo-wrapper">
									<img src="/assets/src/img/straact-bird-blue-logo.png" alt="Straact logo" />
								</div>
								<h1>Straact</h1>
								<h2>Create your free account</h2>
								<CreateUser />
							</section>
							<section className="explore-panel">
								<h4>Trusted by 100.000 students</h4>
								<Button variant="contained" color="primary">Hello World</Button>
							</section>
						</StylesProvider>
					</ThemeProvider>
				</Container>
			</div>
		)
	}
}
