import React, { useState, setState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';
import PreloadFiles from "./PreloadFiles";
import FolderIcon from '@material-ui/icons/Folder';
import OptionsIcon from '@material-ui/icons/MoreHoriz';

function FilesView( props ) {

	const smoothDate = ( changed ) => {
		let smooth = 'Just now';

		if ( changed != '' ) {
			let current = DateTime.local().setZone( 'Europe/Berlin' ).toSQL().split( ' ' )
			current = current[0] + 'T' + current[1]

			let now = DateTime.fromISO( current )
			let start = DateTime.fromISO( changed.replace( ' ', 'T' ) )

			let intervals = [ 'seconds', 'minutes', 'hours', 'days', 'months', 'years' ]
			let date = start.until( now ).toDuration( intervals ).toObject();

			intervals.forEach( ( v, i ) => {
				// Hours always defaults to 1, but we need 0 as default
				if ( intervals[i] == 'hours' && date[v] == 1 ) {
					date[v] = 0
				}

				if ( date[v] > 0 ) {
					smooth = Math.round( date[v] ) + ' ' + ( date[v] > 1 ? intervals[i] : intervals[i].slice( 0, -1 ) ) + ' ago'
				}
			});
		}

		return smooth;
	}

	if ( props.preload ) {
		return (
			<PreloadFiles />
		);
	}

	if ( Array.isArray( props.folders ) ) {
		if ( props.folders.length ) {
			return (
				<section className="files-view">
					<div className="files-view__title">
						Name:
					</div>

					<div className="files-view__date">
						Last edited:
					</div>
					{ props.folders.map((folder) =>
						<Link to={ `/folders/${ folder.folder_id }` } className="file-item" key={ folder.folder_id }>
							<div className="file-item__icon">
								<FolderIcon />
							</div>
							<div className="file-item__title">
								{ folder.name }
							</div>
							<div className="file-item__date">
								{ smoothDate( folder.changed ) }
							</div>
							<div className="file-item__options">
								<OptionsIcon />
							</div>
						</Link>
					)}
				</section>
			);
		} else {
			return (
				<section className="files-view">
					Im empty..
				</section>
			)
		}
	}
}

export default FilesView;
