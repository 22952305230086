import React, { Component } from "react";
import { Button, Typography, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import CreateFolderIcon from '@material-ui/icons/CreateNewFolderRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default class TopBar extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			folderVisible: false,
			newFolder: {
				name: '',
			}
		};
	}

	/**
	* Form submit handler
	*
	*
	* @return boolean
	*/
	createFolder = ( e ) => {
		e.preventDefault();

		if ( this.state.newFolder.name != '' ) {
			const data = {
				name:this.state.newFolder.name,
				location:( this.props.folder_id ? this.props.folder_id : 0),
			}

			fetch( '/endpoints/react/file-handler.php?createFolder', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Cache-Control': 'no-cache'
				},
				body: JSON.stringify( data ),
			})
			.then( res => res.json() )
			.then(
				( result ) => {
					if ( result.response == 'success' ) {
						this.props.appendFolder( result.data );
						this.setState({
							folderVisible: false
						});
					} else {
						setErrorMessage( errorHandler( result.type, result.reason ) );
					}
				},
				( error ) => {
					console.log( error );
				}
			)
		} else {
			return false;
		}
	}

	hideForm = ( e ) => {
		if ( this.popup ) {
			if ( e.target == this.popup ) {
				this.setState({
					folderVisible: false
				});
			}
		}
	}

	showForm = ( e ) => {
		this.setState({
			folderVisible: true
		});
	}

	render() {
		return (
			<section className="top-bar">
				<h1>{ this.props.headline }</h1>

				<div className="icons-wrapper">
					<CreateFolderIcon onClick={ this.showForm } />
					<NotificationsIcon />
				</div>

				{ this.state.folderVisible && (
					<div className="blurry--overlay fade-in" onClick={ this.hideForm } ref={ node => this.popup = node }>
						<div className="container">
							<div className="title">Add new folder</div>
							<div className="form-wrapper field-wrapper">
								<form onSubmit={ this.createFolder } noValidate>
									<TextField
										type="text"
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="foldername-field"
										label="Name"
										name="name"
										autoComplete="new-name"
										onChange={ event => this.state.newFolder.name = event.target.value }
									/>
									{ this.errorMessage }
									<Button type="submit" fullWidth variant="contained" color="primary">Create folder</Button>
								</form>
							</div>
						</div>
					</div>
				)}
			</section>
		)
	}
}
